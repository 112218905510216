<template>
  <b-card>
      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <b-form-group :label="$t('Oluşturma Tarihi Başlangıç')" label-for="dtOlusTar">
            <b-form-input type="date" v-model="dtBaslangicFilter" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group :label="$t('Oluşturma Tarihi Bitiş')" label-for="dtOlusTar">
            <b-form-input type="date" v-model="dtBitisFilter" />
          </b-form-group>
        </b-col>
      </b-row>
  </b-card>
</template>

<script>
export default {
  props: {
    filter: {
      type: String,
      default: 'ContactFormFilter',
    },
  },
  computed: {
    dtBaslangicFilter: {
      get() {
        return this.$store.getters[`contactFormYonetim/get${this.$props.filter}`].dtBaslangic
      },
      set(value) {
        this.$store.commit(`contactFormYonetim/set${this.$props.filter}`, { key: 'dtBaslangic', value })
      },
    },

    dtBitisFilter: {
      get() {
        return this.$store.getters[`contactFormYonetim/get${this.$props.filter}`].dtBitis
      },
      set(value) {
        this.$store.commit(`contactFormYonetim/set${this.$props.filter}`, { key: 'dtBitis', value })
      },
    },
  },
}
</script>
