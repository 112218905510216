<template>
  <b-tabs>
    <b-tab active>
      <template #title>
        <feather-icon icon="MessageCircleIcon" class="mr-50" size="16" />
        <span>{{ $t("Okunmamış Kayıtlar") }}</span>
      </template>
      <crud-button
        :onClickList="fetchContactForms"
        :showAddNew="false"
      />
      <contact-form-filters />
      <b-card no-body class="mb-0">
        <b-table
          class="position-relative"
          :items="getContactForms"
          responsive
          :fields="getColumns"
          primary-key="id"
          show-empty
          empty-text="Kayıt bulunamadı"
        >
          <template #cell(sMesaj)="data">
            <b-link
              @click="showModal(data.item)"
              class="font-weight-bold d-block text-nowrap"
            >
              <span v-if="data.item.sMesaj.length <= 30">
                {{ data.item.sMesaj }}
              </span>
              <span v-else>
                {{ data.item.sMesaj.substring(0, 30) + "..." }}
              </span>
            </b-link>
          </template>
        </b-table>
      </b-card>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="CheckIcon" class="mr-50" size="16" />
        <span>{{ $t("Okunmuş Kayıtlar") }} </span>
      </template>
      <crud-button
        :onClickList="fetchContactForms"
        :showAddNew="false"
      />
      <contact-form-filters :filter="'ContactReadFormFilter'" />
      <b-card no-body class="mb-0">
        <b-table
          class="position-relative"
          :items="getContactReadForms"
          responsive
          :fields="getColumns"
          primary-key="id"
          show-empty
          empty-text="Kayıt bulunamadı"
        >
          <template #cell(sMesaj)="data">
            <b-link
              @click="showModal(data.item)"
              class="font-weight-bold d-block text-nowrap"
            >
              <span v-if="data.item.sMesaj.length <= 30">
                {{ data.item.sMesaj }}
              </span>
              <span v-else>
                {{ data.item.sMesaj.substring(0, 30) + "..." }}
              </span>
            </b-link>
          </template>
        </b-table>
      </b-card>
    </b-tab>
    <b-modal
      ref="modal-message"
      title="Mesaj"
      ok-title="Okundu Olarak İşaretle"
      cancel-title="Kapat"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      no-enforce-focus
      centered
      :ok-disabled="message.isRead"
      @ok="setMessageRead"
    >
    {{ message.sMesaj }}
    </b-modal>
  </b-tabs>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
import AlertService from '@/common/alert.service'
import ContactFormFilters from './ContactFormFilters.vue';

export default {
  components: { ContactFormFilters },

  computed: {
    ...mapGetters('contactFormYonetim', ['getContactForms', 'getContactReadForms', 'getColumns']),
  },
  data() {
    return {
      message: {},
    }
  },
  methods: {
    ...mapActions('contactFormYonetim', ['fetchContactForms', 'setRead']),
    showModal(message) {
      this.message = message;
      this.$refs['modal-message'].show()
    },
    setMessageRead() {
      this.setRead(this.message.id)
      .then(response => {
        if (response.statusCode === 200) {
          this.$toast({
            component: ToastificationSuccess,
            position: 'top-right',
            props: { text: response.message },
          })
          this.fetchContactForms();
        } else {
          AlertService.error(this, response.message)
        }
      })
    },
  },

  mounted() {
    this.fetchContactForms();
  },
}
</script>
